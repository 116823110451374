import React from 'react'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import { graphql, Link } from 'gatsby'
import { HelpCategories, Config, Routes } from '@constants'
import Layout from '@components/Layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import HelmetHelper from '@components/HelmetHelper'

const HelpCategory = (props) => {
  const posts = get(props, 'data.allContentfulHelpPost.nodes')
  const category = get(posts, '0.category.0')

  const categoryObj = HelpCategories.find((item) => item.slug === category)

  if (!categoryObj) {
    return null
  }

  const { name, description, icon: Icon } = categoryObj

  return (
    <Layout disableContainer disableContainerBody disableLinks>
      <HelmetHelper
        title={`${capitalize(name)} | Help Center - ${Config.NAME}`}
        description={description}
      />
      <div className="mx-auto max-w-screen-lg px-6 lg:px-0">
        <div className="pt-10 pb-5">
          <div className="text-sm sm:text-md text-gray-700 mt-2 mb-1">
            <div className="flex items-center gap-2">
              <Link className="text-link" to={Routes.HELP.ALL}>
                All categories
              </Link>
              <span className="text-gray-500">/</span>
              <div className="capitalize text-gray-600">{name}</div>
            </div>
          </div>
          <div className="py-8 px-5 rounded flex items-center gap-5 md:gap-10">
            <Icon className="w-10 h-10 text-gray-500" />
            <div className="flex flex-col gap-1">
              <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold leading-normal capitalize">
                {name}
              </h1>
              <h2 className="text-md md:text-lg text-gray-600">
                {description}
              </h2>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 mb-20">
          {posts.map(
            ({ slug, description: postDescription, title, date, author }) => (
              <Link key={slug} to={Routes.HELP.post(category, slug)}>
                <div className="py-4 px-6 md:py-8 md:px-12 border border-gray-300 rounded flex flex-col items-start gap-3 shadow hover:shadow-lg hover:cursor-pointer transition duration-150 bg-white">
                  <div className="flex flex-col gap-1">
                    <div className="text-lg md:text-xl text-link">{title}</div>
                    <div className="text-xs md:text-md text-gray-600">
                      {postDescription}
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <GatsbyImage
                      className="h-9 w-9 rounded-full ring-1 ring-slate-100"
                      alt={author.name}
                      title={author.name}
                      image={author.image.gatsbyImageData}
                    />
                    <div>
                      <div className="text-mini sm:text-xm leading-relaxed">
                        <span className="text-gray-500">Posted by </span>
                        <span className="text-gray-800">{author.name}</span>
                      </div>
                      <div className="text-mini sm:text-xm text-gray-500 leading-relaxed">
                        Updated on {date}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )
          )}
        </div>
      </div>
    </Layout>
  )
}

export default HelpCategory

export const pageQuery = graphql`
  query CategoryIndexQuery($slug: String!) {
    allContentfulHelpPost(
      filter: { category: { in: [$slug] } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        slug
        title
        description
        category
        date(formatString: "MMMM Do, YYYY")
        author {
          name
          title
          company
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 60
              height: 60
            )
            resize(height: 50, width: 50) {
              src
            }
          }
        }
      }
    }
  }
`
